<main class="container">

  <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedCategoryIndex"
    (selectedTabChange)="onTabChanged($event)" animationDuration="0ms">

    <mat-tab *ngFor="let categoryName of categoriesNames"
      [label]="'nav-bar.search.browse.tabs.'+categoryName | translate">

      <div class="container">

        <div class="grid-search">

          <div class="infinite-sidebar">
            <h2 class="leading">{{'nav-bar.search.browse.filters.header' | translate}}</h2>
            <form [formGroup]="formGroupCategory" *ngIf="formGroupCategory" class="filter">
              <div *ngFor="let filter of categoryData?.filters" class="filters">
                <p>{{ 'nav-bar.search.browse.filters.'+filter.key.toLowerCase() | translate }}</p>

                <div [ngSwitch]="filter?.type">

                  <div *ngSwitchCase="'dropdown'">

                    <mat-form-field *ngIf="filter?.key === 'subcategory'">
                      <input matInput [formControlName]="filter?.key" [matAutocomplete]="auto1" />
                      <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let value of filter?.dropdownValues?.data"
                          [value]="value.key.toUpperCase()">
                          {{'nav-bar.search.browse.filters.drop-down.subcategories.'+value?.key | translate}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field *ngIf="filter?.key === 'category'">
                      <input matInput [formControlName]="filter?.key" [matAutocomplete]="auto1" />
                      <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let value of filter?.dropdownValues?.data"
                          [value]="value.key.toUpperCase()">
                          {{'nav-bar.search.browse.filters.drop-down.categories.'+value?.key | translate}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field *ngIf="filter?.key === 'types'">
                      <input matInput [formControlName]="filter?.key" [matAutocomplete]="auto1" />
                      <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let value of filter?.dropdownValues?.data"
                          [value]="value.key.toUpperCase()">
                          {{'nav-bar.search.browse.filters.drop-down.categories.'+value?.key | translate}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field *ngIf="filter?.key === 'industry'">
                      <input matInput [formControlName]="filter?.key" [matAutocomplete]="auto1" />
                      <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let value of filter?.dropdownValues?.data"
                          [value]="value.key.toUpperCase()">
                          {{'nav-bar.search.browse.filters.drop-down.industries.'+value?.key | translate}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>

                  </div>

                  <div *ngSwitchCase="'text'">
                    <mat-form-field>
                      <input matInput [formControlName]="filter?.key" />
                    </mat-form-field>
                  </div>

                  <div *ngSwitchCase="'tags'">
                    <mat-form-field>
                      <mat-chip-list #chipList [formControlName]="filter?.key">
                        <mat-chip *ngFor="let tag of filter.tagsValues" [selectable]="filter?.selectable"
                          [removable]="filter?.removable" (removed)="remove(tag, filter.key)">
                          {{ tag }}
                          <mat-icon matChipRemove *ngIf="filter?.removable">cancel</mat-icon>
                        </mat-chip>
                        <input [placeholder]="'nav-bar.search.browse.filters.chipList.placeholder' | translate"
                          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          [matChipInputAddOnBlur]="filter.addOnBlur" (matChipInputTokenEnd)="add($event, filter.key)" />
                      </mat-chip-list>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="buttons-wrapper">
                <button mat-stroked-button type="submit" (click)="removeFilters(categoryName)">
                  {{'nav-bar.search.browse.filters.remove-filters-button' | translate}}
                </button>

                <button mat-raised-button color="primary" type="submit" (click)="submit(categoryName)">
                  {{'nav-bar.search.browse.filters.filter-button' | translate}}
                </button>


              </div>
            </form>
          </div>

          <!-- RECORD SEARCH -->
          <div *ngIf="categoryData?.results?.length > 0 && !categoryData?.isProfile" infiniteScroll
            [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="150" (scrolled)="onScroll()" [scrollWindow]="false"
            [fromRoot]="false" class="infinite-scroll-wrap">
            <h2 class="leading">{{'nav-bar.search.browse.tabs.message.top-results' | translate}}</h2>
            <div *ngFor="let result of categoryData?.results" class="card result-wrapper"
              (click)="redirectToResult(result)">
              <h2>{{ result?.title || 'Няма име' }}</h2>

              <div class="buttons-flex">
                <button type="button" mat-stroked-button>{{ result?.category || 'Няма категория' }}</button>
                <button type="button" mat-stroked-button *ngIf="result?.subCategory">{{ result?.subCategory|| 'Няма категория'}}</button>
              </div>
              <div class="result-list" *ngIf="result?.authors?.length > 0">
                <span style="font-weight: bold" class="result-data">Authors:</span>
                <span *ngFor="let author of result?.authors">
                  {{ author }}
                </span>
              </div>

              <div *ngIf="result?.requirements?.length > 0" style="margin-top: 10px">
                <span style="font-weight: bold">Requirements</span>

                <div *ngFor="let requirement of result?.requirements">

                  <div style="margin-top: 5px; display: flex" *ngIf="requirement?.education">
                    <i class="result-data" style="margin-right: 2px">Education:</i>
                    <div style="display: flex; gap: 0.5rem;" *ngIf="categoryName === 'contest' && requirement?.education?.length; else educationString">
                      <span style="font-weight: bold; word-wrap: break-word; max-width: 100px;" *ngFor="let edu of requirement?.education">
                        {{edu.name.length > 40 ? (edu.name | slice:0:40) + '...' : edu.name}}
                      </span>
                    </div>
                    <ng-template #educationString>
                      <span>{{requirement.education | json}}</span>
                      <!-- <span>{{categoryName | json}}</span> -->
                    </ng-template>

                  </div>

                  <div style="margin-top: 5px" *ngIf="requirement['skills']">
                    <i class="result-data">Skills:</i>
                    <span *ngFor="let item of requirement['skills']" class="result-data">
                      {{ item.name }}
                    </span>
                  </div>

                  <div style="margin-top: 5px" *ngIf="requirement['experience']">
                    <i class="result-data">Experience</i>
                    <div [innerHTML]="requirement['experience']"></div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px" [innerHTML]="result?.abstract"></div>
              <div style="margin-top: 20px">
                <span style="font-weight: bold" *ngIf="categoryName == 'contest'">{{ result?.rewardHeader ||
                  'Награда'}}</span>
                <span style="font-weight: bold" *ngIf="categoryName == 'project'">Reward</span>
                <div [innerHTML]="result?.reward"></div>
              </div>

              <div class="result-list" *ngIf="result?.keywords?.length > 0">
                <span *ngFor="let keyword of result?.keywords" class="keyword-wrap">
                  {{ keyword }}
                </span>
              </div>
            </div>
          </div>

          <!-- TALENT SEARCH -->
          <div *ngIf="categoryData?.results.length > 0 && categoryData?.isProfile" infiniteScroll
            [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="150" (scrolled)="onScroll()" [scrollWindow]="false"
            [fromRoot]="false" class="infinite-scroll-wrap">
            <h2 class="leading">{{'nav-bar.search.browse.tabs.message.top-results' | translate}}</h2>
            <div *ngFor="let result of categoryData?.results" class="card result-wrapper">
              <div class="contact-talent">
                <h2>{{ result?.firstName }} {{ result?.lastName }}</h2>
                <div class="talent-action-btns">
                  <button mat-button (click)="openExpertPublicProfile(result.id)" class="contact-talent-btn~"
                    type="button">View talent</button>
                  <button mat-flat-button color="primary" (click)="addContact(result)" class="contact-talent-btn~"
                    type="button">Contact talent</button>
                </div>
              </div>
              <div style="margin-top: 20px">{{ result?.motivation }}</div>

              <div style="margin-top: 10px">
                <b>Degree</b>: {{ result?.degree ? result?.degree : 'No degree listed' }}
              </div>
              <div style="margin-top: 10px">
                <b>Field of Study</b>: {{ result?.field_of_study ? result?.field_of_study : 'No field of study listed'
                }}
              </div>
              <div style="margin-top: 10px" *ngIf="result?.company">
                <b>Company</b>: {{ result?.company }}
              </div>
              <div style="margin-top: 10px" *ngIf="result?.certification">
                <b>Certificate</b>: {{ result?.certification }}
              </div>
            </div>
          </div>

          <div *ngIf="categoryData?.results.length === 0">
            <h2 style="text-align: center">{{'nav-bar.search.browse.tabs.message.no-data' | translate}}</h2>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</main>